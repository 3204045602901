import { type ImageLoaderProps } from 'next/image';

export default function imagesLoaderCDN(imageProps: ImageLoaderProps) {
  const {
    quality,
    src,
    width,
  } = imageProps;

  const url = new URL(process.env.NEXT_PUBLIC_IMAGES_URL ? `${process.env.NEXT_PUBLIC_IMAGES_URL}${src}` : `https://cdn-static.roomster.com/react18${src}`);
  url.searchParams.set('auto', 'webp');
  url.searchParams.set('width', width.toString());
  url.searchParams.set('quality', (quality ?? 75).toString());
  return url.href;
}
