import { atom } from 'jotai';

import { DEFAULT_CURRENCY } from '@/features/currency/constants/currency-contants';
import {
  type CurrenciesList,
  type Currency,
} from '@/features/currency/types/currency-types';

export const currenciesListAtom = atom<CurrenciesList | null>(null);

export const currentCurrencyAtom = atom<Currency>(DEFAULT_CURRENCY);
