export default function placeNameToUrl(locationName: string) {
  return locationName
    .split(', ')
    .join('-')
    .split(',')
    .join('-')
    .split(' ')
    .join('-')
    .toLowerCase();
}
