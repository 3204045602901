import cx from 'classix';
import Image from 'next/image';

import styles from './avatar.module.scss';

interface AvatarProps {
  imageSrc: string;
  alt: string;
  fill?: boolean;
  size?: number;
  view?: 'circle' | 'square';
}

export default function Avatar(props: AvatarProps) {
  const {
    alt,
    fill,
    imageSrc,
    size = 40,
    view = 'circle',
  } = props;

  return (
    <Image
      className={cx(styles.avatar, view === 'square' && styles.avatar__isSquare)}
      src={imageSrc}
      alt={alt}
      width={fill ? undefined : size}
      height={fill ? undefined : size}
      unoptimized
      fill={fill}
    />
  );
}
