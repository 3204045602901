import {
  getCookie,
  type OptionsType,
} from 'cookies-next/client';
import { setCookie } from 'cookies-next/server';

const cookieName = 'keyword';
const durationThirtyDays = 60 * 60 * 24 * 30;

export async function setKeywordCookie(keyword: string, options?: OptionsType): Promise<void> {
  await setCookie(cookieName, keyword, {
    maxAge: durationThirtyDays,
    path: '/',
    ...options,
  });
}

export function getKeywordCookie(): string | false {
  const cookie = getCookie(cookieName);

  return cookie ?? false;
}
