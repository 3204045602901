import { type User } from '@/features/user/types/user-types';

export enum VerificationStatus {
  New = 'new',
  Passed = 'passed',
  Pending = 'pending',
  Rejected = 'rejected',
  Error = 'error',

}

export type UserIdVerification = Record<User['id'], UserIdVerificationDetails | null>;

export interface UserIdVerificationDetails {
  id: string;
  userID: string;
  status: VerificationStatus;
  expiredDate: Date;
  filledDate: Date | null;
  tosAgreedDate: Date;
  paymentDate: Date;
  isExpired: boolean;
  createdDate: Date;
  updatedDate: Date;
  documentCountry: null | string;
  documentType: null | string;
}
